import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Login({ onLogin }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    const apiUrl = process.env.NODE_ENV === 'production'
          ? 'https://api.seneguide.com/pro/login'
          : '/pro/login';

    axios.post(apiUrl, {
      email: email,
      password: password
    })
    .then(response => {
      const token = response.data.token;
      localStorage.setItem('authToken', token);
      onLogin(token);
      navigate('/dashboard');
    })
    .catch(() => {
      setError('Nom d\'utilisateur ou mot de passe incorrect.');
    });
  };

  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
      <div style={{ width: '100%', maxWidth: '400px' }}>
        <div className="text-center" style={{ width: '100%', maxWidth: '400px' }}>
            <img src="/logo-blanc.png" alt="Logo SénéGuide" className="img-fluid mb-3" style={{ maxWidth: '250px' }} />
        </div>
        <div className="card p-4" style={{ width: '100%', maxWidth: '400px' }}>
            <h1 className="text-primary">Se connecter</h1>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <form onSubmit={handleSubmit}>
                <div className="form-floating mb-3">
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="name@example.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <label htmlFor="email">Email</label>
                </div>
                <div className="form-floating mb-3">
                    <input
                        type="password"
                        className="form-control"
                        id="password"
                        placeholder="Mot de passe"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <label htmlFor="password">Mot de passe</label>
                </div>
                <button type="submit" className="btn btn-primary w-100">Se connecter</button>
            </form>
        </div>
      </div>
    </div>
  );
}

export default Login;

