import React, { useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

function Navbar({ onLogout }) {
  const location = useLocation();  // Utiliser pour définir l'élément actif de la navbar
  const navbarRef = useRef(null);  // Référence pour la navbar collapsée

  // Fonction pour fermer le menu après avoir cliqué sur un lien
  const handleNavLinkClick = () => {
    const navbarCollapse = navbarRef.current;
    if (navbarCollapse && navbarCollapse.classList.contains('show')) {
      // Fermer le menu mobile s'il est ouvert
      window.$(navbarCollapse).collapse('toggle');  // Utiliser jQuery pour appeler collapse('hide')
    }
  };

  return (
    <nav className="navbar navbar-expand-xl navbar-light bg-light">
      <div className="container">
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav" ref={navbarRef}>
          <ul className="navbar-nav w-100 justify-content-between nav-pills ms-auto mx-xl-3 my-2 my-xl-0">
            <li className="nav-item">
              <Link className={`nav-link ${location.pathname === '/dashboard' ? 'active' : ''}`} to="/dashboard" onClick={handleNavLinkClick}>Tableau de bord</Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${location.pathname === '/reservations' ? 'active' : ''}`} to="/reservations" onClick={handleNavLinkClick}>Réservations</Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${location.pathname === '/calendrier' ? 'active' : ''}`} to="/calendrier" onClick={handleNavLinkClick}>Calendrier</Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${location.pathname === '/offres' ? 'active' : ''}`} to="/offres" onClick={handleNavLinkClick}>Menu</Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${location.pathname === '/promotions' ? 'active' : ''}`} to="/promotions" onClick={handleNavLinkClick}>Promos</Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${location.pathname === '/horaires' ? 'active' : ''}`} to="/horaires" onClick={handleNavLinkClick}>Horaires</Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${location.pathname === '/fermetures' ? 'active' : ''}`} to="/fermetures" onClick={handleNavLinkClick}>Fermetures</Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${location.pathname === '/photos' ? 'active' : ''}`} to="/photos" onClick={handleNavLinkClick}>Photos</Link>
            </li>
          </ul>
          <button className="btn btn-outline-secondary ms-lg-auto" onClick={onLogout}>
            <i className="bi bi-box-arrow-right"></i>
          </button>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
