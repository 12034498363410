import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReservationListToValidate from './ReservationListToValidate';
import ReservationList from './ReservationList';
import AddressesModal from './AddressesModal';  // Importer le composant AddressesModal

axios.defaults.baseURL = '/'; 

function Dashboard() {
  // État pour stocker les nombres de réservations
  const [data, setData] = useState({
    reservationsPending: 0,
    reservationsToday: 0,
    reservationsPendingList: [],
    reservationsTodayList: [],
  });

  const [error, setError] = useState('');
  const [inactive, setInactive] = useState(false); // État pour gérer l'inactivité
  const [lastUpdated, setLastUpdated] = useState(null); // État pour stocker l'heure de la dernière mise à jour
  const [showModal, setShowModal] = useState(false); // État pour le modal

  // Fonction pour gérer la fermeture du modal
  const handleCloseModal = () => setShowModal(false);

  // Fonction pour ouvrir le modal
  const handleShowModal = () => setShowModal(true);

  // Fonction pour sauvegarder l'addressToken et charger les données
  const handleSaveAddressToken = (addressToken) => {
    localStorage.setItem('addressToken', addressToken); // Sauvegarder le token dans le localStorage
    localStorage.removeItem('reservationSlots');
    localStorage.removeItem('images');
    fetchData(); // Charger les données après avoir enregistré le token
  };

  // Fonction pour récupérer les données du tableau de bord
  const fetchData = () => {
    const token = localStorage.getItem('authToken');
    const addressToken = localStorage.getItem('addressToken'); // Récupérer l'addressToken du localStorage

    if (!addressToken) {
      handleShowModal(); // Ouvrir le modal si addressToken est manquant
      return;
    }

    const apiUrl = process.env.NODE_ENV === 'production'
      ? 'https://api.seneguide.com/pro/dashboard'
      : '/pro/dashboard';

    axios.post(apiUrl, { token: token, addressToken: addressToken })
      .then(response => {
        localStorage.setItem('dashboardData', JSON.stringify(response.data));
        localStorage.setItem('lastUpdated', new Date().toISOString());

        setData(response.data);
        setLastUpdated(new Date()); // Mettre à jour l'heure de la dernière mise à jour
      })
      .catch(error => {
        setError('Erreur lors du chargement des données du tableau de bord.');
      });
  };

  useEffect(() => {
    // Charger les données du localStorage si elles existent
    const savedData = localStorage.getItem('dashboardData');
    const savedLastUpdated = localStorage.getItem('lastUpdated');

    if (savedData) {
      setData(JSON.parse(savedData));
    }

    if (savedLastUpdated) {
      setLastUpdated(new Date(savedLastUpdated));
    }

    // Vérifier si l'addressToken existe, sinon ouvrir le modal
    if (!localStorage.getItem('addressToken')) {
      handleShowModal();
    } else {
      fetchData(); // Récupérer les données lors du montage initial du composant
    }

    // Détecter l'inactivité de l'utilisateur
    let inactivityTimer;
    const resetInactivityTimer = () => {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(() => {
        setInactive(true);
      }, 60000); // 60 secondes d'inactivité
    };

    // Réinitialiser l'inactivité lors des interactions
    window.addEventListener('mousemove', resetInactivityTimer);
    window.addEventListener('keydown', resetInactivityTimer);

    // Mettre à jour toutes les minutes si l'utilisateur est inactif
    const interval = setInterval(() => {
      if (inactive) {
        fetchData(); // Actualiser les données
      }
    }, 60000); // Actualiser toutes les 60 secondes

    // Nettoyage : retirer les écouteurs d'événements et l'intervalle
    return () => {
      clearInterval(interval);
      clearTimeout(inactivityTimer);
      window.removeEventListener('mousemove', resetInactivityTimer);
      window.removeEventListener('keydown', resetInactivityTimer);
    };
  }, [inactive]); // Dépendance à l'état `inactive`

  return (
    <div className="container">
      <div className="d-md-flex justify-content-between align-items-center mt-4">
        <h1>Tableau de bord {data.addressTitle}</h1>
        <button className="btn btn-link text-white p-0" onClick={handleShowModal}>
          Changer d'adresse
        </button>
      </div>

      {/* Affichage de l'heure de la dernière mise à jour */}
      <p className="text-white">
        Dernière mise à jour : {lastUpdated && (
          <span>
            {lastUpdated.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
          </span>
        )}
      </p>

      {error && <p style={{ color: 'red' }}>{error}</p>}

      <div className="row mt-4">
        {/* Carte pour les réservations en attente */}
        <div className="col-md-6">
          <div className={`card mb-4 p-4 ${data.reservationsPending > 0 ? 'bg-warning' : ''}`}>
            <div className="card-body">
              <h5 className="card-title">Réservations en attente</h5>
              <p className="card-text display-6 fw-bold">{data.reservationsPending}</p>
            </div>
          </div>
        </div>

        {/* Carte pour les réservations d'aujourd'hui */}
        <div className="col-md-6">
          <div className="card mb-4 p-4">
            <div className="card-body">
              <h5 className="card-title">Réservations aujourd'hui</h5>
              <p className="card-text display-6 fw-bold">{data.reservationsToday}</p>
            </div>
          </div>
        </div>
      </div>

      <ReservationListToValidate 
        reservations={data.reservationsPendingList}
        onFetch={fetchData}
      />

      <ReservationList 
        reservations={data.reservationsTodayList}
        onFetch={fetchData}
      />

      {/* Utilisation du modal pour entrer l'addressToken */}
      <AddressesModal 
        show={showModal}
        handleClose={handleCloseModal}
        onSave={handleSaveAddressToken}
      />
    </div>
  );
}

export default Dashboard;
