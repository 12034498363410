import React, { useEffect, useState } from 'react';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import axios from 'axios';
import ImagesAdd from './ImagesAdd';  // Importer le composant ImagesAdd

const ItemType = 'IMAGE';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const addCdnPrefix = (url) => {
  const cdnPrefix = 'https://cdn.seneguide.com';
  return url.startsWith(cdnPrefix) ? url : `${cdnPrefix}${url}`;
};

const ImageItem = ({ image, index, moveImage, openModal, deleteImage }) => {
  const [, ref] = useDrag({
    type: ItemType,
    item: { index }
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveImage(draggedItem.index, index);
        draggedItem.index = index;
      }
    }
  });

  return (
    <div className="col-lg-3 col-md-4 col-sm-6 mb-4 bg-transparent" ref={(node) => ref(drop(node))}>
      <div className="card border-0" style={{ position: 'relative' }}>
        <div style={{ cursor: 'move', position: 'relative' }}>
          <div style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
            fontSize: '3rem',
            fontWeight: 'bold',
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
            pointerEvents: 'none'
          }}>
            {image.ordernumber}
          </div>
          <img 
            src={addCdnPrefix(image.thumbnail || image.image)}  
            alt={image.description_image || 'Description indisponible'} 
            className="card-img-top"
            style={{ 
              objectFit: 'cover', 
              height: '250px' 
            }}
          />
        </div>
        <div className="card-body d-flex justify-content-between">
          <button className="btn btn-primary" onClick={() => openModal(image)}>
            <i className="bi bi-zoom-in"></i>
          </button>
          {image.ordernumber > 3 && (
            <button className="btn btn-danger" onClick={() => deleteImage(image.id_image)}>
              <i className="bi bi-trash3"></i>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const Modal = ({ image, closeModal }) => {
  if (!image) return null;

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000
    }} onClick={closeModal}>
      <div style={{ position: 'relative', padding: 16 }}>
        <img 
          src={addCdnPrefix(image.image)} 
          alt={image.description_image || 'Description indisponible'}
          style={{ maxHeight: '80vh', maxWidth: '80vw', objectFit: 'contain' }}
        />
        <button 
          style={{
            position: 'absolute',
            top: 10,
            right: 10,
            padding: '10px 20px',
            backgroundColor: 'white',
            border: 'none',
            cursor: 'pointer',
            fontSize: '16px'
          }} 
          onClick={closeModal}
        >
          <i className="bi bi-x-circle"></i>
        </button>
      </div>
    </div>
  );
};

function ImagesList() {
  const [images, setImages] = useState([]);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  // Récupérer les images stockées dans localStorage
  const loadFromLocalStorage = () => {
    const savedImages = localStorage.getItem('images');
    if (savedImages) {
      return JSON.parse(savedImages);
    }
    return null;
  };

  const saveToLocalStorage = (images) => {
    localStorage.setItem('images', JSON.stringify(images));
  };

  const openModal = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  const deleteImage = (id) => {
    const token = localStorage.getItem('authToken');
    const addressToken = localStorage.getItem('addressToken');

    const apiUrl = process.env.NODE_ENV === 'production'
      ? 'https://api.seneguide.com/pro/images/delete'
      : '/pro/images/delete';

    axios.post(apiUrl, { token: token, addressToken: addressToken, imageId: id })
      .then(() => {
        const updatedImages = images.filter(image => image.id_image !== id);
        setImages(updatedImages);
        saveToLocalStorage(updatedImages);  // Mettre à jour localStorage
      })
      .catch(error => {
        console.error('Erreur lors de la suppression de l\'image:', error);
      });
  };

  const fetchData = () => {
    const token = localStorage.getItem('authToken');
    const addressToken = localStorage.getItem('addressToken');
    
    const apiUrl = process.env.NODE_ENV === 'production'
      ? 'https://api.seneguide.com/pro/images'
      : '/pro/images';

    axios.post(apiUrl, { token: token, addressToken: addressToken })
      .then(response => {
        const imagesData = response.data.images;
        const updatedImages = imagesData.map((image, index) => {
          if (image.ordernumber === 0) {
            return { ...image, ordernumber: index + 1 };
          }
          return image;
        });

        const sortedImages = updatedImages.sort((a, b) => a.ordernumber - b.ordernumber);
        setImages(sortedImages);
        saveToLocalStorage(sortedImages);  // Sauvegarder dans localStorage
      })
      .catch(error => {
        setError('Erreur lors du chargement des images.');
      });
  };

  useEffect(() => {
    const savedImages = loadFromLocalStorage();
    if (savedImages) {
      setImages(savedImages);
    } else {
      fetchData();
    }
  }, []);

  const moveImage = (fromIndex, toIndex) => {
    const reorderedImages = reorder(images, fromIndex, toIndex);
    const updatedOrder = reorderedImages.map((image, index) => ({
      ...image,
      ordernumber: index + 1 
    }));

    setImages(updatedOrder);
    saveToLocalStorage(updatedOrder);  // Mettre à jour localStorage
    updateOrderOnServer(updatedOrder);
  };

  const updateOrderOnServer = (updatedImages) => {
    const token = localStorage.getItem('authToken');
    const addressToken = localStorage.getItem('addressToken');

    const apiUrl = process.env.NODE_ENV === 'production'
      ? 'https://api.seneguide.com/pro/images/edit'
      : '/pro/images/edit';

    axios.post(apiUrl, { token: token, addressToken: addressToken, images: updatedImages })
      .then(() => {
        console.log('Ordre des images mis à jour sur le serveur.');
      })
      .catch(error => {
        console.error('Erreur lors de la mise à jour des ordres:', error);
      });
  };

  const handleImageAdded = () => {
    fetchData();
  };

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="container pb-4">
        <h1 className="mt-4">Photos</h1>
        <div className="row">
          {images.map((image, index) => (
            <ImageItem
              key={image.id_image}
              image={image}
              index={index}
              moveImage={moveImage}
              openModal={openModal}
              deleteImage={deleteImage}
            />
          ))}
        </div>
        <Modal image={selectedImage} closeModal={closeModal} />
        <ImagesAdd onImageAdded={handleImageAdded} />
      </div>
    </DndProvider>
  );
}

export default ImagesList;
