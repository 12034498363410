import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Composant réutilisable pour afficher la liste des réservations sous forme de tableau ou de cartes
function ReservationListToValidate({ reservations, onFetch }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Détecter si l'écran est mobile

  // État pour suivre quelle réservation est en cours de traitement
  const [processing, setProcessing] = useState(null);

  // Fonction pour surveiller la taille de l'écran
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1024);
  };

  // Accepter une réservation
  const handleAccept = (reservationId) => {

    setProcessing(reservationId);

    const token = localStorage.getItem('authToken');
    const addressToken = localStorage.getItem('addressToken');

    const apiUrl = process.env.NODE_ENV === 'production'
          ? 'https://api.seneguide.com/pro/reservations/valid'
          : '/pro/reservations/valid';
    
    axios.post(apiUrl, {
      token: token,
      addressToken: addressToken,
      reservation_id: reservationId,
      action: 2, // Action "2" correspond à l'acceptation
    })
    .then(response => {
      if (response.data.success) {
        console.log('Réservation acceptée avec succès:', reservationId);
        onFetch();
      } else {
        console.log('Erreur lors de l\'acceptation:', response.data.message);
      }
    })
    .catch(error => {
      console.error('Erreur lors de l\'acceptation de la réservation:', error);
    });
  };

  // Refuser une réservation
  const handleReject = (reservationId) => {

    setProcessing(reservationId);

    const token = localStorage.getItem('authToken');
    const addressToken = localStorage.getItem('addressToken');

    const apiUrl = process.env.NODE_ENV === 'production'
          ? 'https://api.seneguide.com/pro/reservations/valid'
          : '/pro/reservations/valid';

    axios.post(apiUrl, {
      token: token,
      addressToken: addressToken,
      reservation_id: reservationId,
      action: 3, // Action "3" correspond au refus
    })
    .then(response => {
      if (response.data.success) {
        console.log('Réservation refusée avec succès:', reservationId);
        // Optionnel : Actualiser les données du tableau de bord après refus
        onFetch();
      } else {
        console.log('Erreur lors du refus:', response.data.message);
      }
    })
    .catch(error => {
      console.error('Erreur lors du refus de la réservation:', error);
    });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize); // Nettoyage
  }, []);

  if (!reservations || reservations.length === 0) {
    return (
      <div className="col-12">
        <h2>Réservations en attente</h2>
        <div className="card mb-4 p-4">
          <p className="mb-0">Aucune réservation en attente.</p>
        </div>
      </div>
    );
  }

  if (isMobile) {
    // Affichage en mode cartes pour mobile
    return (
      <div className="col-12">
        <h2>Réservations en attente</h2>
        {reservations.map((reservation) => (
          <div className="card mb-3 p-3" key={reservation.id}>
              <div>
                <h5 className="mb-1">Client : {reservation.client_name}</h5>
                <p className="mb-1">Téléphone : {reservation.client_phone}</p>
                <p className="mb-1">Nombre de personnes : {reservation.number_of_guests}</p>
                <p className="mb-0">
                  Date :{' '}
                  {new Date(reservation.time).toLocaleDateString([], {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  })}{' '}
                  {new Date(reservation.time).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit'
                  })}
                </p>
              </div>
              <div className="mt-3">
                <button
                  className="btn btn-success text-uppercase btn-sm me-2"
                  onClick={() => handleAccept(reservation.id)}
                  disabled={processing === reservation.id}
                >
                  Accepter
                </button>
                <button
                  className="btn btn-danger text-uppercase btn-sm"
                  onClick={() => handleReject(reservation.id)}
                  disabled={processing === reservation.id}
                >
                  Refuser
                </button>
            </div>
          </div>
        ))}
      </div>
    );
  }

  // Affichage en mode tableau pour les écrans plus larges
  return (
    <div className="col-12">
      <h2>Réservations en attente</h2>
      <div className="card mb-4 p-4">
        <div className="table-responsive">
          <table className="table table-striped mb-0">
            <thead>
              <tr>
                <th scope="col" className="text-uppercase">Jour</th>
                <th scope="col" className="text-uppercase">Client</th>
                <th scope="col" className="text-uppercase">Nombre</th>
                <th scope="col" className="text-uppercase"></th>
              </tr>
            </thead>
            <tbody>
              {reservations.map((reservation) => (
                <tr key={reservation.id}>
                  <td className="text-uppercase align-middle">
                    {new Date(reservation.time).toLocaleDateString([], {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                    })}{' '}
                    {new Date(reservation.time).toLocaleTimeString([], {
                      hour: '2-digit',
                      minute: '2-digit'
                    })}
                  </td>
                  <td className="text-uppercase align-middle">
                    {reservation.client_name}<br /><small>{reservation.client_phone}</small>
                  </td>
                  <td className="text-uppercase align-middle">
                    {reservation.number_of_guests}
                  </td>
                  <td className="text-uppercase align-middle">
                    {reservation.discount && (
                      <>
                        <i className="bi bi-exclamation-diamond me-2"></i>
                        <span>{reservation.discount}</span>
                      </>
                    )}  
                  </td>
                  <td className="text-end align-middle pe-3" nowrap>
                    <button
                      className="btn btn-success text-uppercase btn-sm me-2"
                      onClick={() => handleAccept(reservation.id)}
                      disabled={processing === reservation.id} // Désactiver uniquement pendant le traitement
                    >
                      Accepter
                    </button>
                    <button
                      className="btn btn-danger text-uppercase btn-sm"
                      onClick={() => handleReject(reservation.id)}
                      disabled={processing === reservation.id} // Désactiver uniquement pendant le traitement
                    >
                      Refuser
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ReservationListToValidate;
