import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

function Closes() {
  // États locaux
  const [dateRange, setDateRange] = useState([new Date(), new Date()]); // Période de fermeture sélectionnée
  const [closures, setClosures] = useState([]); // Liste des périodes de fermeture déjà enregistrées
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // Fonction pour récupérer les périodes de fermeture depuis l'API
  useEffect(() => {
    setLoading(true);
    
    const apiUrl = process.env.NODE_ENV === 'production'
          ? 'https://api.seneguide.com/pro/closures'
          : '/pro/closures';
        
    const token = localStorage.getItem('authToken');
    const addressToken = localStorage.getItem('addressToken');
    
    axios
      .post(apiUrl, { token: token, addressToken: addressToken })
      .then((response) => {
        setClosures(response.data.closures);
        setLoading(false);
      })
      .catch((err) => {
        setError('Erreur lors du chargement des périodes de fermeture.');
        setLoading(false);
      });
  }, []);

  // Fonction pour enregistrer une nouvelle période de fermeture
  const handleAddClosure = () => {
    const token = localStorage.getItem('authToken');
    const addressToken = localStorage.getItem('addressToken');

    const [start, end] = dateRange;

    const apiAddUrl = process.env.NODE_ENV === 'production'
          ? 'https://api.seneguide.com/pro/add-closure'
          : '/pro/add-closure';

    axios
      .post(apiAddUrl, { start, end, token, addressToken })
      .then((response) => {
        setClosures([...closures, response.data.newClosure]); // Ajouter la nouvelle période de fermeture
        setDateRange([new Date(), new Date()]); // Réinitialiser la sélection
      })
      .catch((err) => {
        setError("Erreur lors de l'ajout de la période de fermeture.");
      });
  };

  // Fonction pour supprimer une période de fermeture
  const handleDeleteClosure = (closureId) => {
    const token = localStorage.getItem('authToken');
    const addressToken = localStorage.getItem('addressToken');

    const apiDeleteUrl = process.env.NODE_ENV === 'production'
          ? 'https://api.seneguide.com/pro/delete-closure'
          : '/pro/delete-closure';

    axios
      .post(apiDeleteUrl, { closureId, token, addressToken })
      .then(() => {
        setClosures(closures.filter((closure) => closure.id !== closureId));
      })
      .catch((err) => {
        setError("Erreur lors de la suppression de la période de fermeture.");
      });
  };

  return (
    <div className="container mt-4">
      <h1>Périodes de fermeture</h1>
      
      {error && <div className="alert alert-danger" role="alert">{error}</div>}

      {/* Sélecteur de période de fermeture */}
      <div className="card p-4 mb-4 shadow-sm">
        <h5 className="card-title">Ajouter une période de fermeture</h5>
        <DateRangePicker
          onChange={setDateRange}
          value={dateRange}
          format="dd/MM/yyyy"
          className="form-control"
        />
        <button className="btn btn-primary mt-3" onClick={handleAddClosure}>
          Ajouter
        </button>
      </div>

      {/* Liste des périodes de fermeture dans une table */}
      {loading === true ? (
        <></>
      ) : (
      <div className="card p-4 shadow-sm">
        <h5 className="card-title">Périodes de fermeture enregistrées</h5>
        {closures.length === 0 ? (
          <></>
        ) : (
          <div className="table-responsive">
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th scope="col" className="text-uppercase">Début</th>
                  <th scope="col" className="text-uppercase">Fin</th>
                  <th scope="col" className="text-uppercase text-center"></th>
                </tr>
              </thead>
              <tbody>
                {closures.map((closure) => (
                  <tr key={closure.id}>
                    <td className="align-middle">
                      {new Date(closure.startTime).toLocaleString('fr-FR', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric'
                      })}
                    </td>
                    <td className="align-middle">
                      {new Date(closure.endTime).toLocaleString('fr-FR', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric'
                      })}
                    </td>
                    <td className="text-center align-middle">
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => handleDeleteClosure(closure.id)}
                      >
                        Supprimer
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      )}
    </div>
  );
}

export default Closes;
