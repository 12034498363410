import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';

function AddOfferModal({ show, handleClose, groupId, onOfferAdded, offerToEdit }) {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [pricePromo, setPricePromo] = useState('');
  const [duration, setDuration] = useState('');
  const [image, setImage] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (offerToEdit) {
      // Pré-remplir les champs si on édite une offre existante
      setTitle(offerToEdit.title);
      setDescription(offerToEdit.description);
      setPrice(offerToEdit.price);
      setPricePromo(offerToEdit.pricePromo || '');
      setDuration(offerToEdit.duration || '');
    } else {
      // Réinitialiser les champs si on ajoute une nouvelle offre
      setTitle('');
      setDescription('');
      setPrice('');
      setPricePromo('');
      setDuration('');
      setImage(null);
    }
  }, [offerToEdit]);

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const token = localStorage.getItem('authToken');
    const addressToken = localStorage.getItem('addressToken');

    const formData = new FormData();

    formData.append('token', token);
    formData.append('addressToken', addressToken);
    formData.append('groupId', groupId);

    if (offerToEdit) formData.append('offerId', offerToEdit.id); // Passer l'ID de l'offre à modifier

    formData.append('title', title);
    formData.append('description', description);
    formData.append('price', price);
    if (image) formData.append('image', image);

    // Déterminer l'URL en fonction de l'environnement (même URL pour ajout ou modification)
    const apiUrl = process.env.NODE_ENV === 'production'
      ? 'https://api.seneguide.com/pro/offers/add'
      : '/pro/offers/add';

    axios.post(apiUrl, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(response => {
        setLoading(false);
        handleClose();
        onOfferAdded(); // Rafraîchir les offres après l'ajout ou la modification
      })
      .catch(error => {
        setLoading(false);
        setError('Erreur lors de l\'ajout ou de la modification de l\'offre.');
      });
  };

  // Fonction pour supprimer une offre
  const handleDeleteOffer = () => {
    const token = localStorage.getItem('authToken');
    const addressToken = localStorage.getItem('addressToken');

    const apiUrl = process.env.NODE_ENV === 'production'
      ? 'https://api.seneguide.com/pro/offers/delete'
      : '/pro/offers/delete';

    // Envoyer l'ID de l'offre via le corps de la requête POST
    axios.post(apiUrl, {
      token: token,
      addressToken: addressToken,
      offerId: offerToEdit.id // Passer l'ID de l'offre dans le corps
    })
      .then(response => {
        if (response.data.success) {
          handleClose();
          onOfferAdded(); // Rafraîchir les offres après la suppression
        } else {
          setError('Erreur lors de la suppression de l\'offre.');
        }
      })
      .catch(error => {
        setError('Erreur lors de la suppression de l\'offre.');
      });
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{offerToEdit ? 'Modifier l\'offre' : 'Ajouter une nouvelle offre'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          {error && <div className="alert alert-danger">{error}</div>}
          <div className="mb-3">
            <label htmlFor="title" className="form-label">Titre</label>
            <input
              type="text"
              className="form-control"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="description" className="form-label">Description</label>
            <textarea
              className="form-control"
              id="description"
              rows="3"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
          </div>
          <div className="mb-3">
            <label htmlFor="price" className="form-label">Prix</label>
            <input
              type="number"
              className="form-control"
              id="price"
              value={Math.floor(price)}
              onChange={(e) => {
                const value = Math.floor(e.target.value); // Supprime les décimales
                setPrice(value); // Mettre à jour l'état avec la valeur arrondie
              }}
              onKeyPress={(e) => {
                if (e.key === '.' || e.key === ',') {
                  e.preventDefault(); // Empêcher l'utilisateur d'entrer des décimales
                }
              }}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="image" className="form-label">Image</label>
            <input
              type="file"
              className="form-control"
              id="image"
              accept="image/*"
              onChange={handleImageChange}
            />
          </div>
          <div className="d-flex justify-content-between">
            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? 'En cours...' : (offerToEdit ? 'Modifier' : 'Ajouter')}
            </Button>
            {offerToEdit && (
              <Button variant="danger" onClick={handleDeleteOffer} disabled={loading}>
                Supprimer
              </Button>
            )}
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddOfferModal;

