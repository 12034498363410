import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Discounts() {
  // États pour les discounts et les nouveaux champs
  const [discounts, setDiscounts] = useState([]);
  const [currentDiscount, setCurrentDiscount] = useState(null); // Pour l'édition
  const [newDiscount, setNewDiscount] = useState({
    discountText: '',
    discountRate: '',
    startHour: '',
    endHour: '',
    startTime: '',
    endTime: '',
    daysOfWeek: [],
    actif: false,
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false); // Gérer l'affichage du modal
  const [isEditing, setIsEditing] = useState(false); // Savoir si on édite ou on crée

  // Options pour les jours de la semaine (avec valeurs numériques)
  const daysOfWeekOptions = [
    { value: 1, label: 'Lundi' },
    { value: 2, label: 'Mardi' },
    { value: 3, label: 'Mercredi' },
    { value: 4, label: 'Jeudi' },
    { value: 5, label: 'Vendredi' },
    { value: 6, label: 'Samedi' },
    { value: 7, label: 'Dimanche' },
  ];

  // Mapping des numéros de jour aux labels
  const dayNumberToLabel = {
    1: 'Lundi',
    2: 'Mardi',
    3: 'Mercredi',
    4: 'Jeudi',
    5: 'Vendredi',
    6: 'Samedi',
    7: 'Dimanche',
  };

  // Fonction pour normaliser un discount en s'assurant que daysOfWeek est un tableau de nombres
  const normalizeDiscount = (discount) => ({
    ...discount,
    daysOfWeek: discount.daysOfWeek
      ? discount.daysOfWeek.map((day) => parseInt(day, 10))
      : [],
  });

  // Charger les discounts au démarrage
  useEffect(() => {
    setLoading(true);

    const apiUrl =
      process.env.NODE_ENV === 'production'
        ? 'https://api.seneguide.com/pro/discounts'
        : '/pro/discounts';

    const token = localStorage.getItem('authToken');
    const addressToken = localStorage.getItem('addressToken');

    axios
      .post(apiUrl, { token, addressToken })
      .then((response) => {
        const discountsData = response.data.discounts.map(normalizeDiscount);
        setDiscounts(discountsData);
        setLoading(false);
      })
      .catch((err) => {
        setError('Erreur lors du chargement des discounts.');
        setLoading(false);
      });
  }, []);

  // Gérer les changements dans le formulaire
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewDiscount({
      ...newDiscount,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setNewDiscount({
      ...newDiscount,
      [name]: checked,
    });
  };

  const handleDaysChange = (e) => {
    const value = parseInt(e.target.value, 10);
    const { checked } = e.target;
    setNewDiscount({
      ...newDiscount,
      daysOfWeek: checked
        ? [...newDiscount.daysOfWeek, value]
        : newDiscount.daysOfWeek.filter((day) => day !== value),
    });
  };

  // Fonction pour ouvrir le modal pour créer un discount
  const handleCreateDiscount = () => {
    setIsEditing(false); // Mode création
    setNewDiscount({
      discountText: '',
      discountRate: '',
      startHour: '',
      endHour: '',
      startTime: '',
      endTime: '',
      daysOfWeek: [],
      actif: false,
    });
    setShowModal(true); // Afficher le modal
  };

  // Fonction pour ouvrir le modal en mode édition
  const handleEditDiscount = (discount) => {
    setIsEditing(true); // Mode édition
    setCurrentDiscount(discount);
    setNewDiscount({
      discountText: discount.discountText,
      discountRate: discount.discountRate,
      startHour: discount.startHour,
      endHour: discount.endHour,
      startTime: discount.startTime ? discount.startTime : '',
      endTime: discount.endTime ? discount.endTime : '',
      daysOfWeek: discount.daysOfWeek ? discount.daysOfWeek.map((day) => parseInt(day, 10)) : [],
      actif: discount.actif,
    });
    setShowModal(true); // Afficher le modal
  };

  // Fonction pour ajouter ou éditer un discount
  const handleSaveDiscount = () => {
    const token = localStorage.getItem('authToken');
    const addressToken = localStorage.getItem('addressToken');

    const apiUrl =
      process.env.NODE_ENV === 'production'
        ? isEditing
          ? `https://api.seneguide.com/pro/edit-discount`
          : 'https://api.seneguide.com/pro/add-discount'
        : isEditing
        ? `/pro/edit-discount`
        : '/pro/add-discount';

    const requestData = {
      ...newDiscount,
      token,
      addressToken,
      discountId: isEditing ? currentDiscount.id : undefined, // Inclure l'ID si on édite
    };

    axios
      .post(apiUrl, requestData)
      .then((response) => {
        if (isEditing) {
          setDiscounts(
            discounts.map((discount) =>
              discount.id === currentDiscount.id
                ? normalizeDiscount(response.data.updatedDiscount)
                : discount
            )
          );
        } else {
          setDiscounts([...discounts, normalizeDiscount(response.data.newDiscount)]);
        }
        setShowModal(false); // Fermer le modal
      })
      .catch((err) => {
        setError(
          isEditing
            ? 'Erreur lors de la modification du discount.'
            : "Erreur lors de l'ajout du discount."
        );
      });
  };

  // Fonction pour supprimer un discount
  const handleDeleteDiscount = (discountId) => {
    const token = localStorage.getItem('authToken');
    const addressToken = localStorage.getItem('addressToken');

    const apiDeleteUrl =
      process.env.NODE_ENV === 'production'
        ? 'https://api.seneguide.com/pro/delete-discount'
        : '/pro/delete-discount';

    axios
      .post(apiDeleteUrl, { discountId, token, addressToken })
      .then(() => {
        setDiscounts(discounts.filter((discount) => discount.id !== discountId));
      })
      .catch((err) => {
        setError('Erreur lors de la suppression du discount.');
      });
  };

  return (
    <div className="container mt-4 mb-5">
      <h1>Gérer les promotions</h1>

      {error && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}

      {/* Bouton pour créer un discount */}
      <button className="btn btn-success mb-3" onClick={handleCreateDiscount}>
        Ajouter une promotion
      </button>

      {/* Liste des discounts existants */}
      {loading ? (
        <></>
      ) : (
        <div className="card p-4 shadow-sm">
          <h5 className="card-title">Promotions enregistrées</h5>
          {discounts.length === 0 ? (
            <p>Aucune promotion enregistrée.</p>
          ) : (
            <div className="table-responsive">
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th scope="col" className="text-uppercase">
                      Texte
                    </th>
                    <th scope="col" className="text-uppercase">
                      Heures
                    </th>
                    <th scope="col" className="text-uppercase">
                      Jours
                    </th>
                    <th scope="col" className="text-uppercase text-center">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {discounts.map((discount) => (
                    <tr key={discount.id}>
                      <td>{discount.discountText}</td>
                      <td>
                        {discount.startHour} - {discount.endHour}
                      </td>
                      <td>
                        {discount.daysOfWeek && discount.daysOfWeek.length > 0
                          ? discount.daysOfWeek.map((day) => dayNumberToLabel[day]).join(', ')
                          : 'Aucun'}
                      </td>
                      <td className="text-center">
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => handleEditDiscount(discount)}
                        >
                          Modifier
                        </button>
                        <button
                          className="btn btn-danger btn-sm ms-2"
                          onClick={() => handleDeleteDiscount(discount.id)}
                        >
                          Supprimer
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}

      {/* Modal pour créer ou éditer un discount */}
      {showModal && (
        <div
          className="modal fade show d-block"
          tabIndex="-1"
          role="dialog"
          style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {isEditing ? 'Modifier Discount' : 'Ajouter Discount'}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowModal(false)}
                ></button>
              </div>
              <div className="modal-body">
                <div className="form-check form-switch mb-3">
                  <label className="form-check-label" htmlFor="actifSwitch">
                    Actif
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="actifSwitch"
                    name="actif"
                    checked={newDiscount.actif}
                    onChange={handleCheckboxChange}
                  />
                </div>
                <div className="mb-3">
                  <label>Date de début :</label>
                  <input
                    type="date"
                    name="startTime"
                    value={newDiscount.startTime}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>
                <div className="mb-3">
                  <label>Date de fin :</label>
                  <input
                    type="date"
                    name="endTime"
                    value={newDiscount.endTime}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>
                <div className="mb-3">
                  <label>Texte :</label>
                  <input
                    type="text"
                    name="discountText"
                    value={newDiscount.discountText}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>
                <div className="mb-3">
                  <label>Heure de début :</label>
                  <input
                    type="time"
                    name="startHour"
                    value={newDiscount.startHour}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>
                <div className="mb-3">
                  <label>Heure de fin :</label>
                  <input
                    type="time"
                    name="endHour"
                    value={newDiscount.endHour}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>
                <div className="mb-3">
                  <label>Jours de la semaine :</label>
                  {daysOfWeekOptions.map((day) => (
                    <div key={day.value} className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        value={day.value}
                        checked={newDiscount.daysOfWeek.includes(day.value)}
                        onChange={handleDaysChange}
                        id={`day-${day.value}`}
                      />
                      <label
                        className="form-check-label ms-2"
                        htmlFor={`day-${day.value}`}
                      >
                        {day.label}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowModal(false)}
                >
                  Annuler
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSaveDiscount}
                >
                  {isEditing ? 'Enregistrer les modifications' : 'Ajouter promotion'}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Discounts;
