import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Reservations from './components/Reservations';
import Login from './components/Login';
import Navbar from './components/Navbar';
import Closes from './components/Closes';
import Opens from './components/Opens';
import Calendar from './components/Calendar';
import Images from './components/Images';
import ImagesAdd from './components/ImagesAdd';
import Offers from './components/Offers';
import Discounts from './components/Discounts';
import './custom.scss';
import './App.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css';

function App() {
  // État pour gérer l'authentification et le chargement initial
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // État de chargement initial

  // Fonction appelée lorsqu'un utilisateur se connecte
  const handleLogin = (token) => {
    // Persister le token dans le localStorage
    localStorage.setItem('authToken', token);
    setIsAuthenticated(true); // Mise à jour de l'état d'authentification
  };

  // Fonction appelée lors de la déconnexion
  const handleLogout = () => {
    // Supprimer le token du localStorage
    localStorage.removeItem('authToken');
    setIsAuthenticated(false); // Mise à jour de l'état d'authentification
  };

  // Utilisation de useEffect pour vérifier l'état d'authentification au chargement du composant
  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      setIsAuthenticated(true);
    }
    setIsLoading(false); // Terminer le chargement une fois vérifié
  }, []); // L'effet ne s'exécute qu'une seule fois après le montage du composant

  // Gestion du chargement initial (s'affiche avant de savoir si l'utilisateur est authentifié)
  if (isLoading) {
    return <div>Chargement...</div>;
  }

  return (
    <Router>
      <div className="App bg-custom">
        {/* Afficher la barre de navigation si l'utilisateur est authentifié */}
        {isAuthenticated && <Navbar onLogout={handleLogout} />}
        
        <Routes>
          <Route 
            path="/login" 
            element={
              isAuthenticated ? <Navigate to="/dashboard" /> : <Login onLogin={handleLogin} />
            } 
          />
          
          <Route 
            path="/dashboard" 
            element={
              isAuthenticated ? <Dashboard /> : <Navigate to="/login" />
            } 
          />

          <Route 
            path="/offres" 
            element={
              isAuthenticated ? <Offers /> : <Navigate to="/login" />
            } 
          />

          <Route 
            path="/horaires" 
            element={
              isAuthenticated ? <Opens /> : <Navigate to="/login" />
            } 
          />

          <Route 
            path="/fermetures" 
            element={
              isAuthenticated ? <Closes /> : <Navigate to="/login" />
            } 
          />

          <Route 
            path="/photos" 
            element={
              isAuthenticated ? <Images /> : <Navigate to="/login" />
            } 
          />

          <Route 
            path="/photos/ajouter" 
            element={
              isAuthenticated ? <ImagesAdd /> : <Navigate to="/login" />
            } 
          />

          <Route 
            path="/calendrier" 
            element={
              isAuthenticated ? <Calendar /> : <Navigate to="/login" />
            } 
          />

          <Route 
            path="/promotions" 
            element={
              isAuthenticated ? <Discounts /> : <Navigate to="/login" />
            } 
          />
          
          <Route 
            path="/reservations" 
            element={
              isAuthenticated ? <Reservations /> : <Navigate to="/login" />
            } 
          />

          <Route 
            path="/" 
            element={
              isAuthenticated ? <Navigate to="/dashboard" /> : <Navigate to="/login" />
            } 
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
