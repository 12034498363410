import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import axios from 'axios';

function AddressesModal({ show, handleClose, onSave }) {
  const [addressToken, setAddressToken] = useState(localStorage.getItem('addressToken') || ''); // Charger l'addressToken du localStorage
  const [addresses, setAddresses] = useState([]); // Stocker les adresses récupérées
  const [selectedAddress, setSelectedAddress] = useState(''); // Stocker l'adresse sélectionnée
  const [loading, setLoading] = useState(false); // État de chargement
  const [error, setError] = useState(null); // État d'erreur

  // Fonction pour récupérer les adresses lors de l'ouverture du modal
  useEffect(() => {
    if (show) {
      fetchUserAddresses(); // Charger les adresses lorsque le modal est ouvert
    }
  }, [show]);

  const fetchUserAddresses = () => {
    const token = localStorage.getItem('authToken');
    setLoading(true); // Déclencher le mode de chargement

    const apiUrl = process.env.NODE_ENV === 'production'
      ? 'https://api.seneguide.com/pro/useraddresses'
      : '/pro/useraddresses';

    axios.post(apiUrl, { token })
      .then(response => {
        const fetchedAddresses = response.data.addresses;
        setAddresses(fetchedAddresses); // Récupérer les adresses
        setLoading(false); // Arrêter le mode de chargement
      })
      .catch(err => {
        setError('Erreur lors de la récupération des adresses.');
        setLoading(false); // Arrêter le mode de chargement même en cas d'erreur
      });
  };

  // Sauvegarder l'adresse sélectionnée
  const handleSaveToken = () => {
    onSave(selectedAddress); // Passer l'adresse sélectionnée à la fonction onSave
    localStorage.setItem('addressToken', selectedAddress); // Enregistrer l'addressToken dans le localStorage
    handleClose(); // Fermer le modal
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Veuillez choisir votre adresse</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <p className="text-danger">{error}</p>}
        {loading ? (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner animation="border" />
            <span className="ms-2">Chargement des adresses...</span>
          </div>
        ) : (
          <Form.Group controlId="addressSelect">
            <Form.Label>Sélectionner une adresse</Form.Label>
            <Form.Control 
              as="select" 
              value={selectedAddress}
              onChange={(e) => setSelectedAddress(e.target.value)}
            >
              <option value="">-- Choisir une adresse --</option> {/* Ajout d'une option vide pour ne pas présélectionner */}
              {addresses.map((address) => (
                <option key={address.id} value={address.token}>
                  {address.titre} - {address.address}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Annuler
        </Button>
        <Button variant="primary" onClick={handleSaveToken} disabled={loading || !selectedAddress}>
          Sauvegarder
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddressesModal;
