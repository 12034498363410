import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Utiliser useNavigate

function ImagesAdd({ onImageAdded }) {
  const [image, setImage] = useState(null);
  const [description, setDescription] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Gérer le changement de fichier (image)
  const handleImageChange = (event) => {
    setImage(event.target.files[0]);
  };

  // Gérer la soumission du formulaire
  const handleSubmit = (event) => {
    event.preventDefault();

    if (!image) {
      setError('Veuillez sélectionner une image.');
      return;
    }

    const formData = new FormData();
    const token = localStorage.getItem('authToken');
    const addressToken = localStorage.getItem('addressToken');
    
    formData.append('image', image);
    formData.append('description', description);
    formData.append('token', token);
    formData.append('addressToken', addressToken);

    setLoading(true);

    const apiUrl = process.env.NODE_ENV === 'production'
      ? 'https://api.seneguide.com/pro/images/add'
      : '/pro/images/add';
    
    axios.post(apiUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        setLoading(false);
        onImageAdded(); // Appeler la fonction pour mettre à jour la liste des images
        
        // Vider le formulaire après ajout réussi
        setImage(null);
        setDescription('');

        // Optionnel : rediriger vers une autre page après ajout
        navigate('/photos');
      })
      .catch(error => {
        setLoading(false);
        setError('Erreur lors de l\'ajout de l\'image.');
        console.error(error);
      });
  };

  return (
    <div className="mt-4">
      <h1>Ajouter une photo</h1>
      <div className="card p-4 mb-4 shadow-sm">
        <form onSubmit={handleSubmit} className="mt-4">
          {error && <div className="alert alert-danger">{error}</div>}
          
          <div className="mb-3">
            <label htmlFor="image" className="form-label">Image</label>
            <input
              type="file"
              className="form-control"
              id="image"
              accept="image/*"
              onChange={handleImageChange}
              required
            />
          </div>

          <div className="mb-3">
            <label htmlFor="description" className="form-label">Description (optionnel)</label>
            <input
              type="text"
              className="form-control"
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading ? 'Ajout en cours...' : 'Ajouter l\'image'}
          </button>
        </form>
      </div>
    </div>
  );
}

export default ImagesAdd;
