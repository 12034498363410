import React, { useState, useEffect } from 'react';
import axios from 'axios';
import OffersList from './OffersList';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const ItemType = 'GROUP';

// Composant pour gérer chaque groupe avec la fonctionnalité de "drag and drop"
const GroupItem = ({ group, index, moveGroup, handleEditGroupName, handleDeleteGroup, editingGroupId, setEditingGroupId, newGroupName, setNewGroupName, handleOfferAdded }) => {
  const [, ref] = useDrag({
    type: ItemType,
    item: { index }
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveGroup(draggedItem.index, index);
        draggedItem.index = index;
      }
    }
  });

  return (
    <div className="col-12">
    <div ref={(node) => ref(drop(node))} className="px-0 mb-4">
      <div className="card">
        <div className="card-header bg-light fw-bold d-flex justify-content-between align-items-center">
          <i className="bi bi-grip-horizontal"></i>
          {editingGroupId === group.groupId ? (
            <>
              <input
                type="text"
                className="form-control"
                value={newGroupName}
                onChange={(e) => setNewGroupName(e.target.value)}
              />
              <button
                className="btn btn-success ms-2"
                onClick={() => handleEditGroupName(group.groupId)}
              >
                Sauvegarder
              </button>
            </>
          ) : (
            <>
              <h3 className="text-dark mb-0">{group.groupName}</h3>
              <div>
                <button
                  className="btn btn-outline-dark"
                  onClick={() => {
                    setEditingGroupId(group.groupId);
                    setNewGroupName(group.groupName);
                  }}
                >
                  Modifier
                </button>

                {/* Afficher le bouton supprimer seulement si le groupe est vide */}
                {group.offers.length === 0 && (
                  <button
                    className="btn btn-danger ms-2"
                    onClick={() => handleDeleteGroup(group.groupId)}
                  >
                    <i className="bi bi-trash3"></i>
                  </button>
                )}
              </div>
            </>
          )}
        </div>
        <div className="card-body px-0">
          <OffersList
            offers={group.offers}
            onAddOffer={handleOfferAdded}
            groupId={group.groupId}
          />
        </div>
      </div>
    </div>
    </div>
  );
};

function Offers() {
  const [offersGroups, setOffersGroups] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editingGroupId, setEditingGroupId] = useState(null); // Pour suivre le groupe en cours d'édition
  const [newGroupName, setNewGroupName] = useState(''); // Pour stocker le nouveau nom
  const [showAddGroupForm, setShowAddGroupForm] = useState(false); // Gérer l'affichage du formulaire de création de groupe
  const [newGroupNameToAdd, setNewGroupNameToAdd] = useState(''); // Stocker le nom du nouveau groupe

  // Fonction pour récupérer les offres depuis l'API
  const fetchOffers = () => {
    const token = localStorage.getItem('authToken');
    const addressToken = localStorage.getItem('addressToken');

    const apiUrl = process.env.NODE_ENV === 'production'
      ? 'https://api.seneguide.com/pro/offers'
      : '/pro/offers';
  
    axios.post(apiUrl, { token, addressToken })
      .then(response => {
        if (response.data.success) {
          // Trier les groupes par `groupOrder` avant de les stocker dans l'état
          const sortedGroups = response.data.offersGroups.sort((a, b) => a.groupOrder - b.groupOrder);
          setOffersGroups(sortedGroups);
        } else {
          setError("Erreur lors du chargement des offres.");
        }
        setLoading(false);
      })
      .catch(error => {
        setError('Erreur lors de la connexion avec le serveur.');
        setLoading(false);
      });
  };

  // Utiliser useEffect pour récupérer les offres au chargement du composant
  useEffect(() => {
    fetchOffers();
  }, []);

  // Fonction pour gérer la modification du nom du groupe
  const handleEditGroupName = (groupId) => {
    const token = localStorage.getItem('authToken');
    const addressToken = localStorage.getItem('addressToken');

    const apiUrl = process.env.NODE_ENV === 'production'
      ? 'https://api.seneguide.com/pro/offers/group/edit'
      : '/pro/offers/group/edit';

    axios.post(apiUrl, { token, addressToken, groupId, newGroupName })
      .then(response => {
        if (response.data.success) {
          const updatedGroups = offersGroups.map(group => 
            group.groupId === groupId ? { ...group, groupName: newGroupName } : group
          );
          setOffersGroups(updatedGroups);
          setEditingGroupId(null);
          setNewGroupName('');
        } else {
          setError("Erreur lors de la modification du groupe.");
        }
      })
      .catch(error => {
        setError('Erreur lors de la modification du groupe.');
      });
  };

  // Fonction pour gérer l'ajout d'un nouveau groupe
  const handleAddGroup = () => {
    const token = localStorage.getItem('authToken');
    const addressToken = localStorage.getItem('addressToken');

    const apiUrl = process.env.NODE_ENV === 'production'
      ? 'https://api.seneguide.com/pro/offers/group/add'
      : '/pro/offers/group/add';

    axios.post(apiUrl, { token, addressToken, groupName: newGroupNameToAdd })
      .then(response => {
        if (response.data.success) {
          // Ajouter le nouveau groupe dans l'état actuel
          setOffersGroups([...offersGroups, {
            groupId: response.data.groupId,
            groupName: newGroupNameToAdd,
            offers: []
          }]);
          setNewGroupNameToAdd(''); // Réinitialiser le champ
          setShowAddGroupForm(false); // Masquer le formulaire après l'ajout
          fetchOffers();
        } else {
          setError("Erreur lors de l'ajout du groupe.");
        }
      })
      .catch(error => {
        setError('Erreur lors de l\'ajout du groupe.');
      });
  };

  // Fonction pour réordonner les groupes
  const moveGroup = (fromIndex, toIndex) => {
    const updatedGroups = [...offersGroups];
    const [movedGroup] = updatedGroups.splice(fromIndex, 1);
    updatedGroups.splice(toIndex, 0, movedGroup);
  
    // Mettre à jour l'ordre localement
    const reorderedGroups = updatedGroups.map((group, index) => ({
      ...group,
      groupOrder: index + 1 // Réassigne un groupOrder en fonction de la nouvelle position
    }));
  
    setOffersGroups(reorderedGroups);
  
    // Envoyer l'ordre mis à jour au serveur
    const token = localStorage.getItem('authToken');
    const addressToken = localStorage.getItem('addressToken');

    const apiUrl = process.env.NODE_ENV === 'production'
      ? 'https://api.seneguide.com/pro/offers/group/update-order'
      : '/pro/offers/group/update-order';
  
    axios.post(apiUrl, { token, addressToken, groups: reorderedGroups })
      .then(response => {
        if (!response.data.success) {
          setError("Erreur lors de la mise à jour de l'ordre des groupes.");
        }
      })
      .catch(error => {
        setError('Erreur lors de la mise à jour de l\'ordre des groupes.');
      });
  };

  // Fonction pour supprimer un groupe vide
  const handleDeleteGroup = (groupId) => {
    const token = localStorage.getItem('authToken');
    const addressToken = localStorage.getItem('addressToken');

    const apiUrl = process.env.NODE_ENV === 'production'
      ? 'https://api.seneguide.com/pro/offers/group/delete'
      : '/pro/offers/group/delete';

    axios.post(apiUrl, { token, groupId })
      .then(response => {
        if (response.data.success) {
          // Supprimer le groupe de la liste
          setOffersGroups(offersGroups.filter(group => group.groupId !== groupId));
        } else {
          setError("Erreur lors de la suppression du groupe.");
        }
      })
      .catch(error => {
        setError('Erreur lors de la suppression du groupe.');
      });
  };

  const handleOfferAdded = () => {
    fetchOffers(); // Rafraîchir les offres après l'ajout d'une nouvelle offre
  };

  if (loading) {
    return <></>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="container mt-4 mb-5">
        <h1 className="mb-4">Offres</h1>

        {/* Ajouter un groupe */}
        <div className="mb-4">
          {showAddGroupForm ? (
            <div className="card p-4">
              <h5>Ajouter un nouveau groupe</h5>
              <input
                type="text"
                className="form-control mb-2"
                placeholder="Nom du groupe"
                value={newGroupNameToAdd}
                onChange={(e) => setNewGroupNameToAdd(e.target.value)}
              />
              <div className="d-flex">
                <button className="btn btn-primary me-2" onClick={handleAddGroup}>Ajouter le groupe</button>
                <button className="btn btn-secondary" onClick={() => setShowAddGroupForm(false)}>Annuler</button>
              </div>
            </div>
          ) : (
            <button className="btn btn-light" onClick={() => setShowAddGroupForm(true)}>
              Ajouter un groupe
            </button>
          )}
        </div>

        <div className="row">
          {offersGroups.length === 0 ? (
            <p>Aucune offre disponible pour le moment.</p>
          ) : (
            offersGroups.map((group, index) => (
              <GroupItem
                key={group.groupId}
                group={group}
                index={index}
                moveGroup={moveGroup}
                handleEditGroupName={handleEditGroupName}
                handleDeleteGroup={handleDeleteGroup}
                editingGroupId={editingGroupId}
                setEditingGroupId={setEditingGroupId}
                newGroupName={newGroupName}
                setNewGroupName={setNewGroupName}
                handleOfferAdded={handleOfferAdded}
              />
            ))
          )}
        </div>
      </div>
    </DndProvider>
  );
}

export default Offers;
