import React, { useState } from 'react';
import axios from 'axios';

// Composant réutilisable pour afficher la liste des réservations sous forme de tableau
function ReservationList({ reservations, onFetch }) {
  // État pour suivre quelle réservation est en cours de traitement
  const [processing, setProcessing] = useState(null);

  const handleShow = (reservationId) => {

    setProcessing(reservationId);

    const token = localStorage.getItem('authToken');
    const addressToken = localStorage.getItem('addressToken');

    const apiUrl = process.env.NODE_ENV === 'production'
          ? 'https://api.seneguide.com/pro/reservations/show'
          : '/pro/reservations/show';
    
    axios.post(apiUrl, {
      token: token,
      addressToken: addressToken,
      reservation_id: reservationId,
      action: 1, // Action "2" correspond à l'acceptation
    })
    .then(response => {
      if (response.data.success) {
        console.log('Réservation acceptée avec succès:', reservationId);
        onFetch();
      } else {
        console.log('Erreur lors de l\'acceptation:', response.data.message);
      }
    })
    .catch(error => {
      console.error('Erreur lors de l\'acceptation de la réservation:', error);
    });
  };

  const handleNoShow = (reservationId) => {

    setProcessing(reservationId);

    const token = localStorage.getItem('authToken');
    const addressToken = localStorage.getItem('addressToken');
    
    const apiUrl = process.env.NODE_ENV === 'production'
          ? 'https://api.seneguide.com/pro/reservations/show'
          : '/pro/reservations/show';

    axios.post(apiUrl, {
      token: token,
      addressToken: addressToken,
      reservation_id: reservationId,
      action: 2, // Action "2" correspond à l'acceptation
    })
    .then(response => {
      if (response.data.success) {
        console.log('Réservation acceptée avec succès:', reservationId);
        onFetch();
      } else {
        console.log('Erreur lors de l\'acceptation:', response.data.message);
      }
    })
    .catch(error => {
      console.error('Erreur lors de l\'acceptation de la réservation:', error);
    });
  };

  // Fonction pour obtenir l'heure actuelle de Dakar
  const getCurrentTimeInDakar = () => {
    return new Date().toLocaleString('en-US', { timeZone: 'Africa/Dakar' });
  };

  if (!reservations || reservations.length === 0) {
    return (
      <div className="col-12">
        <h2>Réservations du jour</h2>
        <div className="card mb-4 p-4">
          <p className="mb-0">Aucune réservation aujourd'hui.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="col-12">
      <h2>Réservations du jour</h2>
      <div className="card mb-4 p-4">
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col" className="text-uppercase">Heure</th>
              <th scope="col" className="text-uppercase">Client</th>
              <th scope="col" className="text-uppercase">Nombre</th>
              <th scope="col" className="text-uppercase"></th>
              <th scope="col" className="text-uppercase"></th>
            </tr>
          </thead>
          <tbody>
            {reservations.map((reservation) => {
              // Heure actuelle à Dakar
              const nowInDakar = new Date(getCurrentTimeInDakar());
              const reservationTime = new Date(reservation.time);

              return (
                <tr key={reservation.id}>
                  <td className="text-uppercase align-middle ps-3">
                    {reservationTime.toLocaleTimeString([], {
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </td>
                  <td className="text-uppercase align-middle">{reservation.client_name}<br /><small>{reservation.client_phone}</small></td>
                  <td className="text-uppercase align-middle">
                    {reservation.number_of_guests}
                  </td>
                  <td className="text-uppercase align-middle">
                    {reservation.discount && (
                      <>
                        <i className="bi bi-exclamation-diamond me-2"></i>
                        <span>{reservation.discount}</span>
                      </>
                    )}  
                  </td>
                  <td className="text-end align-middle pe-3">
                    {reservation.no_show === "0" && (
                    <>
                    <button
                      className="btn btn-success text-uppercase btn-sm me-2"
                      onClick={() => handleShow(reservation.id)}
                      disabled={processing === reservation.id}
                    >
                      Arrivé
                    </button>
                    {nowInDakar > reservationTime && (
                      <button
                        className="btn btn-danger text-uppercase btn-sm"
                        onClick={() => handleNoShow(reservation.id)}
                        disabled={processing === reservation.id}
                      >
                        Non présenté
                      </button>
                    )}
                    </>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ReservationList;
