import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, Button, Form } from 'react-bootstrap';

const Opens = () => {
  const [opens, setOpens] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedOpen, setSelectedOpen] = useState(null);
  const [firstStartTime, setFirstStartTime] = useState('');
  const [firstEndTime, setFirstEndTime] = useState('');
  const [secondStartTime, setSecondStartTime] = useState('');
  const [secondEndTime, setSecondEndTime] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const fetchOpens = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const addressToken = localStorage.getItem('addressToken');

        const apiUrl = process.env.NODE_ENV === 'production'
          ? 'https://api.seneguide.com/pro/opens'
          : '/pro/opens';

        const response = await axios.post(apiUrl, { token, addressToken });
        if (response.data.success) {
          setOpens(response.data.opens);
        } else {
          setError('Erreur lors de la récupération des données');
        }
      } catch (err) {
        setError('Erreur lors de la récupération des données');
      } finally {
        setLoading(false);
      }
    };

    fetchOpens();
  }, []);

  const handleOpenModal = (open) => {
    setSelectedOpen(open);
    setFirstStartTime(open.firstStartTime || '');
    setFirstEndTime(open.firstEndTime || '');
    setSecondStartTime(open.secondStartTime || '');
    setSecondEndTime(open.secondEndTime || '');
    setIsOpen(open.isOpen);
    setShowModal(true);
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem('authToken');
      const addressToken = localStorage.getItem('addressToken');

      const updatedOpen = {
        id: selectedOpen.id,
        isOpen,
        firstStartTime,
        firstEndTime,
        secondStartTime,
        secondEndTime,
      };

      const apiEditUrl = process.env.NODE_ENV === 'production'
          ? 'https://api.seneguide.com/pro/opens/edit'
          : '/pro/opens/edit';


      const response = await axios.post(apiEditUrl, {
        token,
        addressToken,
        opens: [updatedOpen],
      });

      if (response.data.success) {
        setOpens((prevOpens) =>
          prevOpens.map((open) =>
            open.id === updatedOpen.id
              ? { ...open, isOpen, firstStartTime, firstEndTime, secondStartTime, secondEndTime }
              : open
          )
        );
        setShowModal(false);
      } else {
        setError('Erreur lors de la sauvegarde des données');
      }
    } catch (err) {
      setError('Erreur lors de la sauvegarde des données');
    }
  };

  if (loading) {
    return <></>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container mt-4">
      <h1>Horaires d'ouverture</h1>
      <div className="card p-4 mb-4 shadow-sm">
        {opens.length === 0 ? (
          <></>
        ) : (
          <div className="table-responsive mt-3">
            <table className="table table-bordered table-light table-striped">
              <thead>
                <tr>
                  <th className="ps-3">Jour</th>
                  <th>Ouvert</th>
                  <th colSpan="2">Horaires</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {opens.map((open) => (
                  <tr key={open.id}>
                    <td className="ps-3">{open.day}</td>
                    <td className="text-center">
                      <div className="form-check form-switch mx-auto">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id={`switch-${open.id}`}
                          checked={open.isOpen}
                          readOnly
                        />
                        <label className="form-check-label" htmlFor={`switch-${open.id}`}></label>
                      </div>
                    </td>
                    <td>
                      {open.firstStartTime && open.firstEndTime
                        ? `${open.firstStartTime} - ${open.firstEndTime}`
                        : 'N/A'}
                    </td>
                    <td>
                      {open.secondStartTime && open.secondEndTime
                        ? `${open.secondStartTime} - ${open.secondEndTime}`
                        : 'N/A'}
                    </td>
                    <td className="text-center">
                      <i
                        className="bi bi-clock"
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleOpenModal(open)}
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Modifier les horaires</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedOpen && (
            <Form>
              <Form.Group className="mb-3 d-flex justify-content-between">
                <Form.Label>Ouvert</Form.Label>
                <div className="form-check form-switch">
                  <Form.Check
                    type="switch"
                    id="isOpenSwitch"
                    checked={isOpen}
                    onChange={(e) => setIsOpen(e.target.checked)}
                  />
                </div>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Premier créneau - Heure de début</Form.Label>
                <Form.Control
                  type="time"
                  value={firstStartTime}
                  onChange={(e) => setFirstStartTime(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Premier créneau - Heure de fin</Form.Label>
                <Form.Control
                  type="time"
                  value={firstEndTime}
                  onChange={(e) => setFirstEndTime(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Deuxième créneau - Heure de début</Form.Label>
                <Form.Control
                  type="time"
                  value={secondStartTime}
                  onChange={(e) => setSecondStartTime(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Deuxième créneau - Heure de fin</Form.Label>
                <Form.Control
                  type="time"
                  value={secondEndTime}
                  onChange={(e) => setSecondEndTime(e.target.value)}
                />
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Enregistrer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Opens;