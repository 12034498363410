import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/fr';

function ReservationCalendar() {
  // Initialisation de la locale française
  moment.locale('fr');

  // État pour les créneaux de réservation
  const [slots, setSlots] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  // Charger les créneaux de réservation depuis le localStorage ou une API
  useEffect(() => {
    const token = localStorage.getItem('authToken');
    const addressToken = localStorage.getItem('addressToken');

    const apiUrl = process.env.NODE_ENV === 'production'
      ? 'https://api.seneguide.com/pro/slots'
      : '/pro/slots';

    axios
      .post(apiUrl, { token, addressToken })
      .then((response) => {
        if (response.data && response.data.slots) {
          const slotsArray = Object.values(response.data.slots);
          setSlots(slotsArray);
          localStorage.setItem('reservationSlots', JSON.stringify(slotsArray)); // Persister les résultats
        } else {
          setSlots([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        setError('Erreur lors du chargement des créneaux de réservation.');
        setLoading(false);
      });
  }, []);

  // Fonction pour marquer un créneau comme disponible ou complet
  const toggleSlotAvailability = (slotId, isAvailable) => {
    const token = localStorage.getItem('authToken');
    const addressToken = localStorage.getItem('addressToken');

    const apiUrl = process.env.NODE_ENV === 'production'
      ? 'https://api.seneguide.com/pro/slots/complet'
      : '/pro/slots/complet';

    axios
      .post(apiUrl, { token, addressToken, slot: slotId, action: isAvailable ? 0 : 1 })
      .then(() => {
        const updatedSlots = slots.map((slot) =>
          slot.id === slotId ? { ...slot, isAvailable: !isAvailable } : slot
        );
        setSlots(updatedSlots);
        localStorage.setItem('reservationSlots', JSON.stringify(updatedSlots)); // Mettre à jour le localStorage
      })
      .catch((err) => {
        setError('Erreur lors de la mise à jour du créneau.');
      });
  };

  // Grouper les créneaux par jour
  const groupSlotsByDay = () => {
    return slots.reduce((acc, slot) => {
      const date = moment(slot.date).format('dddd DD/MM/YYYY');
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(slot);
      return acc;
    }, {});
  };

  const groupedSlots = groupSlotsByDay();

  return (
    <div className="container pb-4">
      <h1 className="mt-4">Créneaux de réservation</h1>
      {error && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}
      {loading ? (
        <></>
      ) : (
        Object.keys(groupedSlots).map((day) => (
          <div key={day} className="mb-4">
            <h3 className="text-start mb-3 text-white text-uppercase my-3">{day}</h3>
            <div className="row">
              {groupedSlots[day].map((slot) => (
                <div key={slot.id} className="col-md-3 col-lg-2 mb-4">
                  <div className={`card h-100 ${slot.reservations && slot.reservations > 0 ? 'bg-warning' : ''}`}>
                    <div className="card-body">
                      <h4>{slot.time}</h4>
                      <p className="mb-0"><b>{slot.reservations}</b> <small>réservation{slot.reservations && slot.reservations > 1 ? 's' : ''}</small></p>
                    </div>
                    <div className="card-footer text-center p-2">
                      <div className="d-grid gap-2">
                        <button
                          className={`btn text-uppercase btn-sm ${slot.isAvailable ? 'bg-light' : 'bg-danger text-white'}`}
                          onClick={() => toggleSlotAvailability(slot.id, slot.isAvailable)}
                        >
                          {slot.isAvailable ? 'Disponible' : 'Complet'}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))
      )}
    </div>
  );
}

export default ReservationCalendar;
