import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Reservations() {
  // État pour stocker les réservations
  const [reservations, setReservations] = useState([]);
  const [error, setError] = useState('');
  const [activeTab, setActiveTab] = useState('today'); // 'upcoming', 'past', ou 'today'

  // Obtenir l'heure actuelle de Dakar
  const getDakarTime = () => {
    const now = new Date();
    now.setMinutes(now.getMinutes() + now.getTimezoneOffset());
    return now;
  };

  // Appel API pour récupérer les réservations
  useEffect(() => {
    const token = localStorage.getItem('authToken'); // Supposons que vous ayez enregistré le token dans localStorage
    const addressToken = localStorage.getItem('addressToken');

    const apiUrl = process.env.NODE_ENV === 'production'
          ? 'https://api.seneguide.com/pro/reservations'
          : '/pro/reservations';

    axios
      .post(apiUrl, { token: token, addressToken: addressToken })
      .then((response) => {
        setReservations(response.data.reservationsList);
      })
      .catch((error) => {
        console.error('Erreur lors du chargement des réservations.', error);
        setError('Erreur lors du chargement des réservations.');
      });
  }, []);

  // Séparer les réservations passées, à venir et du jour
  const nowInDakar = getDakarTime();
  const startOfToday = new Date(nowInDakar);
  startOfToday.setHours(0, 0, 0, 0); // Début de la journée (00:00)
  const endOfToday = new Date(nowInDakar);
  endOfToday.setHours(23, 59, 59, 999); // Fin de la journée (23:59:59)

  const pastReservations = reservations.filter(
    (reservation) => new Date(reservation.time) < nowInDakar
  );
  const upcomingReservations = reservations.filter(
    (reservation) => new Date(reservation.time) >= nowInDakar
  );
  const todayReservations = reservations.filter(
    (reservation) => new Date(reservation.time) >= startOfToday && new Date(reservation.time) <= endOfToday
  );

  const renderStatusBadge = (status) => {
    // Convertir en entier si `status` est une chaîne de caractères
    const parsedStatus = parseInt(status, 10);
  
    switch (parsedStatus) {
      case 1:
        return <span className="badge text-bg-warning">En attente</span>;
      case 2:
        return <span className="badge text-bg-success">Acceptée</span>;
      case 3:
        return <span className="badge text-bg-danger">Refusée</span>;
      default:
        return <span className="badge text-bg-secondary">Inconnu</span>;
    }
  };

  // Définir le contenu à afficher en fonction de l'onglet actif
  const renderContent = () => {
    let reservationsToDisplay = [];
    if (activeTab === 'upcoming') {
      reservationsToDisplay = upcomingReservations;
    } else if (activeTab === 'past') {
      reservationsToDisplay = pastReservations;
    } else if (activeTab === 'today') {
      reservationsToDisplay = todayReservations;
    }

    if (reservationsToDisplay.length === 0) {
      return (
        <div className="card d-none d-lg-block mb-4 p-4">
          <p className="mb-0">Aucune réservation {activeTab === 'upcoming' ? 'à venir' : activeTab === 'past' ? 'passée' : "d'aujourd'hui"}.</p>
        </div>
      );
    } else {
      return (
        <div className="card h-100 d-none d-lg-block shadow-sm p-4">
            <div className="table-responsive">
            <table className="table table-striped">
                <thead>
                <tr>
                    <th scope="col" className="text-uppercase">Heure</th>
                    <th scope="col" className="text-uppercase">Client</th>
                    <th scope="col" className="text-uppercase">Nombre</th>
                    <th scope="col" className="text-uppercase"></th>
                    <th scope="col" className="text-uppercase"></th>
                </tr>
                </thead>
                <tbody>
                {reservationsToDisplay.map((reservation) => (
                    <tr key={reservation.id}>
                    <td className="text-uppercase align-middle ps-3">
                        {new Date(reservation.time).toLocaleString([], {
                        weekday: 'short',
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        })}
                    </td>
                    <td className="text-uppercase align-middle">{reservation.client_name}<br /><small>{reservation.client_phone}</small></td>
                    <td className="text-uppercase align-middle">{reservation.number_of_guests}</td>
                    <td className="text-uppercase align-middle">
                        {renderStatusBadge(reservation.status)}
                        {reservation.no_show === '2' && (
                            <span className="badge text-bg-danger ms-2">Non présenté</span>
                        )}
                    </td>
                    <td className="text-uppercase align-middle">
                        {reservation.discount ? reservation.discount : 'Aucune'}
                    </td>
                    </tr>
                ))}
                </tbody>
            </table>
            </div>
        </div>
      );
    }
  };

  const renderCards = () => {
    let reservationsToDisplay = [];
    if (activeTab === 'upcoming') {
      reservationsToDisplay = upcomingReservations;
    } else if (activeTab === 'past') {
      reservationsToDisplay = pastReservations;
    } else if (activeTab === 'today') {
      reservationsToDisplay = todayReservations;
    }

    if (reservationsToDisplay.length === 0) {
      return (
        <div className="card d-lg-none mb-4 p-4">
          <p className="mb-0">Aucune réservation {activeTab === 'upcoming' ? 'à venir' : activeTab === 'past' ? 'passée' : "d'aujourd'hui"}.</p>
        </div>
      );
    } else {
      return (
        <div className="row d-lg-none">
          {reservationsToDisplay.map((reservation) => (
            <div key={reservation.id} className="col-12 mb-4">
              <div className="card h-100 shadow-sm">
                <div className="card-body">
                  <h5 className="card-title text-uppercase">
                    {new Date(reservation.time).toLocaleString([], {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </h5>
                  <p className="card-text">
                    <strong>Client :</strong> {reservation.client_name}<br />
                    <strong>Téléphone :</strong> {reservation.client_phone}<br />
                    <strong>Nombre de personnes :</strong> {reservation.number_of_guests}<br />
                    <strong>Réduction :</strong> {reservation.discount ? reservation.discount : 'Aucune'}<br />
                    {renderStatusBadge(reservation.status)}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    }
  };

  return (
    <div className="container mt-4">
      <h1>Historique des réservations</h1>
      {error && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}

      {/* Boutons pour basculer entre les réservations à venir, passées et du jour */}
      <div className="btn-group mt-2 mb-4">
        <button
          className={`btn ${activeTab === 'upcoming' ? 'btn-light' : 'btn-outline-light'}`}
          onClick={() => setActiveTab('upcoming')}
        >
          À venir
        </button>
        <button
          className={`btn ${activeTab === 'today' ? 'btn-light' : 'btn-outline-light'}`}
          onClick={() => setActiveTab('today')}
        >
          Aujourd'hui
        </button>
        <button
          className={`btn ${activeTab === 'past' ? 'btn-light' : 'btn-outline-light'}`}
          onClick={() => setActiveTab('past')}
        >
          Passées
        </button>
      </div>

      {/* Affichage du contenu selon l'onglet actif */}
      {/* Affichage sous forme de table sur les grands écrans */}
      {renderContent()}

      {/* Affichage sous forme de cartes sur les petits écrans */}
      {renderCards()}
    </div>
  );
}

export default Reservations;
