import React, { useState, useEffect } from 'react';
import AddOfferModal from './AddOfferModal';
import { useDrag, useDrop } from 'react-dnd';
import axios from 'axios';

const ItemType = 'OFFER';

const OfferItem = ({ offer, index, moveOffer, onEdit }) => {
  const [, ref] = useDrag({
    type: ItemType,
    item: { index }
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveOffer(draggedItem.index, index);
        draggedItem.index = index;
      }
    }
  });

  const formatPrice = (price) => {
    return new Intl.NumberFormat('fr-FR', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      useGrouping: true
    }).format(price);
  };

  return (
    <li className="list-group-item" ref={(node) => ref(drop(node))}>
      <div className="d-flex justify-content-start align-items-start">
        <i className="bi bi-grip-horizontal"></i>
        <div className="ps-2 ps-md-3">
          <h6 className="m-0">{offer.title}</h6>
          <small>{offer.description}</small>
        </div>
        <span className="ms-auto text-muted text-nowrap ms-1">{formatPrice(offer.price)}</span>
        <i
          className="bi bi-pencil-square ms-3 ms-md-4"
          style={{ cursor: 'pointer' }}
          onClick={() => onEdit(offer)} // Ouvrir le modal pour l'édition
        ></i>
      </div>
    </li>
  );
};

function OffersList({ offers, onAddOffer, groupId }) {
  const [updatedOffers, setUpdatedOffers] = useState([]);

  // Trier les offres par ordernumber lors de la réception des nouvelles offres
  useEffect(() => {
    if (offers && offers.length > 0) {
      const sortedOffers = [...offers].sort((a, b) => a.orderNumber - b.orderNumber);
      setUpdatedOffers(sortedOffers);
    }
  }, [offers]);

  const [offerToEdit, setOfferToEdit] = useState(null); // Offre en cours d'édition
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = (offer = null) => {
    setOfferToEdit(offer); // Si une offre est fournie, nous l'éditons
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setOfferToEdit(null); // Réinitialiser l'offre en cours d'édition après fermeture du modal
  };

  const moveOffer = (fromIndex, toIndex) => {
    const newOffers = [...updatedOffers];
    const [movedOffer] = newOffers.splice(fromIndex, 1);
    newOffers.splice(toIndex, 0, movedOffer);
    setUpdatedOffers(newOffers);

    // Mettre à jour l'ordre sur le serveur
    const reorderedOffers = newOffers.map((offer, index) => ({
      id: offer.id,
      ordernumber: index + 1, // On peut supposer que le premier élément est 1
    }));

    const token = localStorage.getItem('authToken');
    const addressToken = localStorage.getItem('addressToken');

    const apiUrl = process.env.NODE_ENV === 'production'
      ? 'https://api.seneguide.com/pro/offers/update-order'
      : '/pro/offers/update-order';

    axios.post(apiUrl, { token, addressToken, reorderedOffers })
      .then(response => {
        if (response.data.success) {
          console.log('L\'ordre des offres a été mis à jour avec succès.');
        } else {
          console.error('Erreur lors de la mise à jour de l\'ordre des offres.');
        }
      })
      .catch(error => {
        console.error('Erreur de communication avec le serveur :', error);
      });
  };

  return (
    <>
      <ul className="list-group list-group-flush">
        {updatedOffers.map((offer, index) => (
          <OfferItem
            key={offer.id}
            offer={offer}
            index={index}
            moveOffer={moveOffer}
            onEdit={handleShowModal} // Passer l'offre à éditer au modal
          />
        ))}
      </ul>

      <div className="ms-3 mt-3">
        <button className="btn btn-success" onClick={() => handleShowModal()}>
          Ajouter
        </button>
      </div>

      <AddOfferModal
        show={showModal}
        handleClose={handleCloseModal}
        onOfferAdded={onAddOffer}
        groupId={groupId}
        offerToEdit={offerToEdit} // Passer l'offre à éditer si elle existe
      />
    </>
  );
}

export default OffersList;
